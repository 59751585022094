.contentArea {
  width: 100%;
}
.title {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}
.option {
  font-size: 0.875rem;
  font-family: "PoppinsRegular";
}
.input {
  background-image: url(../../assets/images/utility_icons/dropdown-icon.svg) !important;
}
