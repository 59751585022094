/* .leftDiv {
  border: 1px solid red;
} */

.dropzone {
  width: 24.188rem;
  height: 12.438rem;
  /* border: 1px dashed #95BCE0; */
  border-style: dashed;
  border-color: #95bce0;
  border-width: 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image {
  width: 3.438rem;
  height: 3.438rem;
  margin-bottom: 8px;
}

.textDropzone {
  font-size: 1rem;
  font-family: "PoppinsRegular";
  margin: 0;
  padding: 0;
  color: #b7b7b7;
}

.textColor {
  font-size: 1rem;
  font-family: "PoppinsSemiBold";
  color: #0979f9;
  text-decoration: underline;
}

.uploadedPicture {
  /* width: 15.063rem;
    height: 7.5rem; */
  height: 12rem;
  width: auto;
  max-width: 350px;
  object-fit: contain;
  /* width: 36.875rem;
      height: 44.25rem; */
}

.removePicture {
  display: none;
}

.showPicture {
  display: block;
  max-width: 24rem;
  max-height: 12.4rem;
}

.rightDiv {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}

.uploadRight {
  /* margin-left: 1rem; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.uploadContent {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* border: 1px solid red; */
}

.iconSize {
  color: #2c7be5;
  font-size: 1.2rem;
}

.text {
  /* width: 21.5rem;
    height: 4.313rem; */
  font-size: 0.875rem;
  /* padding-left: 0.1rem; */
  margin-left: 1rem;
  font-family: "PoppinsRegular";
  /* border: 1px solid red; */
}

.btnArea {
  /* display: flex;
    align-items: center;
    justify-content: center; */
  /* border: 1px solid red; */
  margin-left: 2rem;
}

.button {
  font-family: "PoppinsSemiBold";
  width: 7.563rem;
  height: 2.563rem;
  border-radius: 0.4rem;
}

.leftSpace {
  margin-left: 1.5rem;
}
