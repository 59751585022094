.loader-main {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-content {
  height: 10rem;
  width: 10rem;
}
