.progressFlow {
  border-top: 2px solid #f0f4fb;
  margin-top: 2.125rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  padding-left: 0;
  font-family: "PoppinsMedium";
}

/* .item .fa-circle {
  color: #95bce0;
}

.item.active > .fa-circle {
  color: #2c7be5;
} */

.item {
  text-decoration: none;
  list-style: none;
}

/* .btn {
  display: flex !important;
  border-radius: 0.4rem !important;
} */

.button {
  width: 7.563rem;
  height: 2.563rem;
  /* font-size: 1.125rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 8px 20px; */
  display: flex !important;
  border-radius: 0.4rem !important;
}

@media screen and (max-width: 375px) {
  .button {
    width: 4rem;
    height: 2.4rem;
    font-size: 1rem;
    padding: 0px;
  }
}
