/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Remove padding and margin */
* {
  margin: 0;
  padding: 0;
  border: 0;
  /* overflow: hidden; */
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "kalpurush";
  src: local("kalpurush"),
    url("./fonts/kalpurush.ttf") format("truetype");
}
