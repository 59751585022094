.container {
  /* border: 1px solid red; */
  padding: 1vw 5vw;
  margin-bottom: 2vw;
  position: relative;
}

.scrollToTopIcon {
  position: fixed;
  bottom: 4rem;
  right: 2rem;
  height: 3rem;
  width: 3rem;
  border: 1px solid #2c7be5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollToTopIcon:hover {
  cursor: pointer;
}

.scrollTopIcon {
  color: #2c7be5;
  font-size: 2rem;
}

.header {
  margin-top: 3vw;
  padding: 2vw;
  font-family: "PoppinsSemiBold";
  text-align: center;
  /* border: 1px solid red; */
}

.subHeader {
  margin: 2rem 0;
  text-decoration: underline;
  font-family: "PoppinsMedium";
}

.question {
  font-family: "PoppinsSemiBold";
  /* font-size: 2rem; */
}

.answer {
  font-family: "PoppinsRegular";
}

.textUnderline {
  text-decoration: underline;
}

.fontItalics li {
  font-style: italic;
}

.clickable {
  text-decoration: underline;
}
.clickable:hover {
  cursor: pointer;
}
