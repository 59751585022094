.dropzone {
  width: 24.188rem;
  height: 12.438rem;
  /* border: 1px dashed #95BCE0; */
  border-style: dashed;
  border-color: #95bce0;
  border-width: 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.innerText {
  font-size: 1rem;
  font-family: "PoppinsRegular";
  margin: 0;
  padding: 0;
  color: #b7b7b7;
}
.textColor {
  font-size: 1rem;
  font-family: "PoppinsSemiBold";
  color: #0979f9;
  text-decoration: underline;
}
