.inputArea{
    width: 100%;
}
.label{
    font-size: 1.125rem;
    font-family: "PoppinsSemiBold";
}
.input{
    font-size: 1rem;
    font-family: "PoppinsRegular";
}