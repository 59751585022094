/* .leftDiv {
  border: 1px solid red;
} */

.dropzone {
  width: 24.188rem;
  height: 12.438rem;
  /* border: 1px dashed #95BCE0; */
  border-style: dashed;
  border-color: #95bce0;
  border-width: 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.textDropzone {
  font-size: 1rem;
  font-family: "PoppinsRegular";
  margin: 0;
  padding: 0;
  color: #b7b7b7;
}

.textColor {
  font-size: 1rem;
  font-family: "PoppinsSemiBold";
  color: #0979f9;
  text-decoration: underline;
}

.rightDiv {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}

.uploadRight {
  /* margin-left: 1rem; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.uploadContent {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* border: 1px solid red; */
}

.iconSize {
  color: #2c7be5;
  font-size: 1.2rem;
}

.text {
  /* width: 21.5rem;
  height: 4.313rem; */
  font-size: 0.875rem;
  /* padding-left: 0.1rem; */
  margin-left: 1rem;
  font-family: "PoppinsRegular";
  /* border: 1px solid red; */
}

.btnArea {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* border: 1px solid red; */
  margin-left: 2.2rem;
}

.button {
  font-family: "PoppinsSemiBold";
  width: 7.563rem;
  height: 2.563rem;
  border-radius: 0.4rem;
}

.leftSpace {
  margin-left: 1.5rem;
}

.uploadedFileContainer {
  min-height: 3.75rem;
  /* width: 48%; */
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-family: "PoppinsRegular";
  /* font-size: 0.75rem; */
  position: relative;
  cursor: pointer;
  /* border: 1px solid red; */
}

.fileName {
  /* border: 1px solid red; */
  max-width: 70%;
}

.iconArchive {
  font-size: 1.875rem;
  color: #95bce0;
}

.iconDownload {
  font-size: 1.875rem;
  color: #2c7be5;
  position: absolute;
  right: 0;
}

/* .fileText {
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  font-family: "PoppinsRegular";
  flex-wrap: wrap;
} */
