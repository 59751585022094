.menu {
  padding-top: 15px;
  color: #95bce0;
}

.active-item {
  color: #0979f9;
}

.active-item::after {
  background-color: #0979f9;
}

.menu-item {
  /* border: 1px solid red; */
  font-family: "PoppinsSemiBold";
  padding: 10px 0 10px 3vw;
  font-size: 1vw;
  position: relative;
}

.menu-item::after {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

/* .menu-item:hover::after {
  background-color: #0979f9;
} */

.menu-item:hover {
  color: #0979f9;
  cursor: pointer;
}

/* dropdown  */
.menu-dropdown {
  font-size: 1.25rem;
}

.menu .menu-dropdown-area-hidden {
  /* display: none; */
  /* visibility: hidden; */
  opacity: 0;
  height: 0;
  /* transition: height 0.3s ease-in-out, opacity 0.2s ease-in-out; */
}
.menu .menu-dropdown-area-visible {
  /* visibility: visible; */
  /* display: block; */
  transition: height 0.3s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 1;
  /* height: 8rem; */
}

.menu-dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  margin-left: 3.5rem;
  overflow: hidden;
}
.menu-dropdown-icon-circle {
  font-size: 8px;
  margin-right: 0.8rem;
}
.menu-dropdown-rotate-0 {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 0.5s;
}
.menu-dropdown-rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: transform 0.5s;
}
.menu-dropdown-link {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
}

.menu-dropdown-link:hover {
  color: #0979f9;
}

.menu-dropdown-link-active {
  font-family: "PoppinsSemiBold";
  color: #0979f9;
}

.welcome {
  /* border: 1px solid red; */
  position: fixed;
  bottom: 80px;
  width: 16vw;
  /* max-width: 267px; */
  /* min-width: 200px; */
  /* padding-left: 50px; */
  padding-left: 3vw;
  font-family: "PoppinsSemiBold";
  font-size: 1vw;
}

.welcome #welcomeMsg {
  color: #0979f9;
}

.welcome #clock {
  color: #010247;
  font-size: 0.8vw;
}

.icon-sun {
  color: #0979f9;
  font-size: 3.1vw;
  margin-bottom: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(11, 2, 51);
  border-radius: 3px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  } */
