.otp-area{
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
}
.otp-area-input{
    width: 100%;
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0.3em 0.5rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #0979F9;
    border-color: #0979F9;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #0979F9;
    font-size: 1.813rem;
    font-family: "PoppinsSemiBold";
}
.otp-area-input:focus{
    color: #0979F9;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
