.content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 10px;
}
.innerContent {
  margin-top: 3.125rem;
  width: 50.172rem;
  height: auto;
}
/* .tabs-custom{
  margin-top: 2rem;
  border-bottom: 2px solid #E3EBF6 !important;
}

.tabs-custom > .nav-item{
  padding-right: 3rem;
}
.tabs-custom > .nav-item > .nav-link{
  font-size: 1.125rem;
  font-family: "PoppinsMedium";
  color: #E5E5E5;
  padding: 0.5rem 0.8rem;
}
.tabs-custom > .nav-item > .nav-link.active{
  color: #2C7BE5;
  border-color: #fff;
  border-bottom: 2px solid #2C7BE5;
} */
.tabs {
  margin-top: 2rem;
  border-bottom: 2px solid #e3ebf6;
  display: flex;
  padding-left: 0;
}
.tabItem {
  list-style: none;
  padding-right: 3rem;
  margin: 0.4rem 0.5rem;
}
.tabLink {
  font-size: 1.125rem;
  font-family: "PoppinsMedium";
  color: #95bce0;
  /* color: #E5E5E5; */
  cursor: pointer;
  padding: 0.46rem 0.6rem;
}
.tabLink:hover {
  /* color: #E5E5E5; */
  color: #858585;
}
.tabActive {
  color: #2c7be5;
  border-color: #fff;
  border-bottom: 2px solid #2c7be5;
}
