.card-layout {
  width: 30vmax;
  min-width: 350px;
  /* margin-left: auto;
    margin-right: 10vmax;
    margin-top: 5vh; */

  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  /* margin-left: 40vmin;
    margin-top: 10vmin; */
}

.card-layout-current {
  padding: 2rem 10vw;
}

.toggle {
  margin-top: 10px;
  position: relative;
  display: inline-block;
  width: 67px;
  height: 34.5px;
  background-color: #f5f5f5;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
}

/* After slide changes */
.toggle:after {
  content: "";
  position: absolute;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: #e2e2e2;
  background-image: url(../assets/images/gb.svg);
  background-position: center;
  background-size: 34px 34px;
  top: 2px;
  left: 3px;
  transition: all 0.2s;
}

/* Toggle text */
.textLanguage {
  font-size: 16px;
  font-family: "PoppinsSemiBold";
  color: #010247;
  padding-top: 5px;
  padding-left: 10px;
  /* text-align: center; */
  margin: 0;
}

/* Checkbox checked effect */
.checkbox:checked + .toggle::after {
  left: 34px;
}

/* Checkbox checked toggle label bg color */
.checkbox:checked + .toggle:after {
  background-color: #e2e2e2;
  background-image: url(../assets/images/bd.png);
  /* background-position: 34px 32px; */
  background-size: 30px 30px;
}

/* Checkbox vanished */
.checkbox {
  display: none;
}

@media screen and (max-width: 767px) {
  .card-layout {
    /* width: 50vmax; */
    min-width: 300px;
    margin: 5vh auto;

    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

.logo-login {
  height: 5rem;
  width: auto;
  /* max-width: 150px; */
  margin-bottom: 20px;
  /* border: 1px solid red; */
}

.logo-avsecID {
  width: auto;
  /* max-width: 150px; */
  height: 5rem;
  /* margin-bottom: 20px; */
  margin-left: -10px;
  /* border: 1px solid red; */
}

.page-title {
  font-family: "PoppinsSemiBold";
  color: #0979f9;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.page-subtitle {
  font-family: "PoppinsSemiBold";
  font-size: 1rem;
}

.form-text {
  font-family: "PoppinsSemiBold";
}

.text-sm {
  font-size: 0.7rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
}

.text-md {
  font-size: 0.9rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
}

.text-md-underline {
  text-decoration: underline;
  color: #0979f9;
}

.form-control.is-invalid {
  border-color: #f01e1f !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
}
.was-validated .form-control:valid {
  border-color: #0979f9 !important;
  background-image: url(../assets/images/utility_icons/check.svg) !important;
}
