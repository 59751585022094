.allNotifications {
  height: 50px;
  color: #0979f9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3ebf6;
  /* background-color: #f9fbfd; */
  /* background-color: #e2e2e2; */
  /* background-color: #f5f5f5; */
  font-size: 0.8rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.notificationContainerInside {
  max-height: 450px;
  /* height: 23vw; */
  z-index: 8;
  width: 22vw;
  max-width: 380px;
  min-width: 250px;
  /* border: 1px solid red; */
  overflow-y: scroll;
}
