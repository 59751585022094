.titleArea{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.starterText{
    font-size: 0.875rem;  /* 14px */
    font-family: "PoppinsMedium";
    color: #95AED1;
    margin-bottom: 0.625rem;
}
.headerText{
    font-size: 1.625rem;  /* 26px */
    font-family: "PoppinsMedium";
    margin-bottom: 0.625rem;
}
.subText{
    font-size: 0.875rem;  /* 14px */
    font-family: "PoppinsRegular";
}