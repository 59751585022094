.starter-text{
    font-size: 0.875rem;  /* 14px */
    font-family: "PoppinsMedium";
    color: #95AED1;
    margin-bottom: 0.625rem;
}
.header-text{
    font-size: 1.625rem;  /* 26px */
    font-family: "PoppinsMedium";
    margin-bottom: 0.625rem;
}
.sub-title-text{
    font-size: 0.875rem;  /* 14px */
    font-family: "PoppinsRegular";
}