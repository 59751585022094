.marginHeader {
  margin-top: 1.8rem !important;
}

.headerAttachment {
  font-size: 1.125rem;
  color: #2c7be5;
  font-family: "PoppinsSemiBold";
}

.headerText {
  font-size: 1rem;
  font-family: "PoppinsMedium";
}

.text {
  font-size: 0.75rem;
  font-family: "PoppinsRegular";
}

.signArea {
  width: 100%;
  margin-bottom: 50px;
}

.validity {
  margin-top: -30px;
  margin-bottom: 50px;
}

.label {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
  margin-bottom: 20px !important;
}

.textBold {
  font-family: "PoppinsSemiBold";
}

/* error log */
.errorLog {
  width: 16vw;
  /* min-width: 220px; */
  position: fixed;
  top: 22vh;
  /* left: 70%; */
  right: 20px;
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
  /* border: 1px solid red; */
}

.errorLog > div:last-of-type {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0.625rem !important;
}

.errorLog > div:first-of-type {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0 !important;
}

.errorLog > div:first-of-type + .errorLog > div > h2 > button {
  border-top-left-radius: calc(0.625rem - 1px) !important;
}

.errorLog > div > h2 > button {
  background-color: #2c7be5 !important;
  color: #fff !important;
  /* font-size: 1.25rem; */
  font-family: "PoppinsRegular";
  padding: 0.7rem 1.25rem;
}

.errorLog > div > h2 > button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  filter: brightness(0) invert(1);
}

.accordionBody {
  width: 100%;
  /* height: 40vh; */
  max-height: 40vh;
  /* height: 45vh; */
  overflow-y: auto;
  /* border: 1px solid red; */
  /* border-bottom-left-radius: 10px; */
}

.errorLogItem {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  /* padding-bottom: 1rem; */
  /* border: 1px solid red; */
}

.errorLogItem:not(:last-child) {
  content: "";
  padding-bottom: 1rem;
}

.errorLogItemLeft {
  width: 25px;
  height: 25px;
  border: 0.13rem solid #ff3a41;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
.errorLogIconContain {
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorLogItem:not(:last-child) .errorLogItemLeft::after {
  content: "";
  width: 0.13rem;
  height: 100%;
  top: 25px;
  /* left: 1vw; */
  position: absolute;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */
  background-color: #ffe9eb;
}
.errorIcon {
  color: #ff3a41;
  font-size: 15px;
}
.errorLogItemRight {
  width: 80%;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.errorLogItemHeader {
  margin-top: 0.3rem;
  font-size: 0.825rem;
  margin-bottom: 0;
  font-family: "PoppinsRegular";
}

.show {
  animation: show_slide 1s ease forwards;
}
@keyframes show_slide {
  0% {
    transform: translateX(100%);
  }
  40% {
    transform: translateX(-10%);
  }
  80% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-10px);
  }
}
