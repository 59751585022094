.headerTitle {
  font-size: 1.625rem;
  font-family: "PoppinsMedium";
  color: #2c7be5;
  margin-bottom: 1.25rem;
}
.genderArea {
  margin-top: 0.25rem;
}
.genderLabel {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}
.uploadArea {
  margin-top: 0.25rem;
}
.uploadLabel {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}
.textBold{
  font-family: "PoppinsSemiBold";
}
