.stepperArea{
    display: flex;
    justify-content: center;
    align-items: center;
}
.stepper{
    margin-top: 2.063rem;
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}


