.content-adjuster-applicant {
  width: 100%;
  height: auto;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  /* border: 1px solid red; */
}
.inner-content-applicant {
  margin-top: 1.5rem;
  width: 60%;
  min-width: 700px;
  height: auto;
  /* border: 1px solid red; */
}
.applicant-top-header,
.margin-primary-header {
  border-bottom: 1px solid #e3ebf6;
}
.applicant-id-header {
  font-size: 1.625rem;
  font-family: "PoppinsMedium";
}
.id-header-color {
  color: #0979f9;
  text-decoration: underline;
}
.applicant-primary-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.625rem;
  font-family: "PoppinsMedium";
  color: #0979f9;
}
.applicant-secondary-header {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.625rem;
  font-family: "PoppinsMedium";
  color: #95bce0;
}
/* direction for image and file */
.picture-field {
  display: flex;
  flex-direction: column;
}
.upload-picture-field-label_2 {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}
.image-styling {
  width: 12.125rem;
  height: auto;
  /* height: 14.563rem; */
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
}
/* file upload */
.file-upload-view-item {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  height: 3.75rem;
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.file-upload-view__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.file-upload-view__icon {
  font-size: 1.875rem;
  color: #95bce0;
}
.file-upload-view__text {
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  font-family: "PoppinsRegular";
  flex-wrap: wrap;
}
.file-upload-view-buttons {
  font-size: 1.5rem;
}
.file-upload-view-buttons .icon-blue {
  cursor: pointer;
  color: #0979f9;
}
.file-upload-view-buttons .icon-red {
  color: #ff3a41;
}
.upload-singular-file-field {
  width: 100%;
}
.upload-singular-file-field-label {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}
/* part E */
.confirm-header-tertiary-text {
  font-size: 1.25rem;
  font-family: "PoppinsMedium";
  color: #95bce0;
  /* color: #2c7be5; */
  /* margin-bottom: 0.5rem; */
}
/* part F */
.group-list-text-header {
  font-size: 1rem;
  font-family: "PoppinsMedium";
}
.group-list-text {
  font-size: 0.875rem;
  font-family: "PoppinsRegular";
}

.authorized-signatory-text {
  font-size: 0.875rem;
  font-family: "PoppinsRegular";
}

.authorized-signatory-text-bold {
  font-family: "PoppinsMedium";
}

.signature-image-styling {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 12.125rem;
  height: 4.625rem;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
}
.signature-image-display {
  height: 4rem;
  width: auto;
  object-fit: contain;
}

.btn-area-confirm-details {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
}
.btn-revert-custom {
  width: 10.563rem;
  height: 2.563rem;
  font-family: "PoppinsMedium";
  font-size: 1rem;
}
.btn-confirm-custom {
  width: 9.563rem;
  height: 2.563rem;
  font-family: "PoppinsMedium";
  font-size: 1rem;
}

.btn-custom-red {
  background-color: #ff3a41 !important;
  color: #fff !important;
}

.btn-custom-blue {
  background-color: #0979f9 !important;
  color: #fff !important;
}
