.view{
    list-style: none;
    margin: 0;
    padding: 0;
}
.item{
    height: 3.75rem;
    box-shadow: 4px 4px 10px rgba(199, 226, 255, .51);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.content{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.icon{
    font-size: 1.875rem;
    color: #95BCE0;
}
.text{
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    font-family: "PoppinsRegular";
    flex-wrap: wrap;
}
.btnArea{
    font-size: 1.5rem;
}
.iconBlue{
    color: #0979F9;
}