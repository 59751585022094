.confirm-header-title-text {
  font-size: 1.625rem;
  font-family: "PoppinsMedium";
  color: #2c7be5;
  margin-bottom: 1.25rem;
}
.confirm-header-secondary-text {
  font-size: 1.625rem;
  font-family: "PoppinsMedium";
  color: #95bce0;
  margin-bottom: 1.25rem;
}
.confirm-header-tertiary-text {
  font-size: 1.25rem;
  font-family: "PoppinsMedium";
  color: #2c7be5;
  margin-bottom: 0.5rem;
}
.group-list-text-header {
  font-size: 1rem;
  font-family: "PoppinsMedium";
}
.group-list-text {
  font-size: 0.875rem;
  font-family: "PoppinsRegular";
}
/* direction for image and file */
.picture-field {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.leftPortion {
  /* border: 1px solid red; */
  padding-right: 1rem;
}
.rightPortion {
  /* border: 1px solid red; */
  padding-left: 1rem;
}

.upload-picture-field-label_2 {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}

.image-styling {
  width: 10rem;
  height: auto;
  /* width: 12.125rem;
  height: 14.563rem; */
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
}

.assetContainer {
  display: flex;
  flex-direction: row;
}

/* file upload */
.file-upload-asset {
  min-height: 3.75rem;
  /* width: 48%; */
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  font-family: "PoppinsRegular";
  position: relative;
  cursor: pointer;
  font-size: 0.75rem;
  /* border: 1px solid red; */
}

.assetFileName {
  /* border: 1px solid red; */
  max-width: 70%;
}

.iconArchive {
  font-size: 1.875rem;
  color: #95bce0;
}

.iconDownload {
  font-size: 1.875rem;
  color: #2c7be5;
  position: absolute;
  right: 0;
}

.file-upload-view__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.file-upload-view__icon {
  font-size: 1.875rem;
  color: #95bce0;
}

.file-upload-view__text {
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  font-family: "PoppinsRegular";
  flex-wrap: wrap;
}
/* .file-upload-view__action {
  border: none;
  background-color: white;
  font-size: 1.25rem;
  color: #ff3a41;
} */

.signArea {
  width: 100%;
  margin-bottom: 50px;
}

.textBold {
  font-family: "PoppinsSemiBold";
}

.label {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
  margin-bottom: 20px !important;
}

.signature-image-styling {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 12.125rem;
  height: 4.625rem;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
}
.signature-image-display {
  /* height: 4rem;
  width: auto;
  object-fit: contain; */
  max-width: 12rem;
  max-height: 4.5rem;
}

.btn-area-confirm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 45%;
  /* border: 1px solid red; */
}

.btn-confirm-custom {
  width: 7.563rem;
  height: 2.563rem;
}

.btn-custom-orange {
  background-color: #f67837 !important;
  color: #fff !important;
}

.btn-custom-blue {
  background-color: #0979f9 !important;
  color: #fff !important;
}

.uploadedFileContainer {
  min-height: 3.75rem;
  /* width: 48%; */
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-family: "PoppinsRegular";
  position: relative;
  cursor: pointer;
  /* border: 1px solid red; */
}

.iconArchive {
  font-size: 1.875rem;
  color: #95bce0;
}

.iconDownload {
  font-size: 1.875rem;
  color: #2c7be5;
  position: absolute;
  right: 0;
}

.self__declaration_red {
  border: 0.175rem solid #f01e1f;
  border-radius: 10px;
}

.self__declaration_green {
  border: 0.175rem solid #44c524;
  border-radius: 10px;
}
