.profile {
  display: flex;
  /* flex-direction: row; */
  /* position: relative; */
  width: 100%;
  height: 48%;
  border-radius: 10px;
  justify-content: space-between;
  align-items: stretch;
  /* border: 1px solid red; */
}

.user-profile {
  width: 37.5%;
  /* height: 100%; */
  border-radius: 10px;
  background-color: #fff;
  /* box-shadow: 18px 38px 63px rgba(230, 240, 255, 0.47); */
  box-shadow: 18px 5px 63px rgba(230, 240, 255, 0.47);
  border: 1px solid #e9f4ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  /* border: 1px solid red; */
}

.user-profile .profile-pic {
  height: 7vw;
  width: 7vw;
  min-width: 50px;
  min-height: 50px;
  /* margin: 10px; */
  /* padding-top: 1.2vw; */
  border-radius: 50%;
  /* display: flex;
  align-items: center;
  justify-content: center;
  position: relative; */
  /* padding: 4px; */
  overflow: hidden;
  border: 3px solid #f9fbfd;
  /* border: 3px solid red; */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11);
}

.user-profile .profile-pic img {
  width: 100%;
  object-fit: contain;
  /* height: auto;
  position: absolute; */
  /* border: 3px solid red; */
}

.user-profile .profile-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1vw;
}

.profile-welcome {
  font-family: "PoppinsMedium";
  color: #0979f9;
  font-size: 0.9vw;
}

.profile-name {
  font-family: "PoppinsMedium";
  font-size: 1.2vw;
}

.profile-gsat {
  font-family: "PoppinsSemiBold";
  font-size: 0.9vw;
}

.profile-designation {
  font-family: "PoppinsRegular";
  font-size: 0.75vw;
  text-align: center;
  padding: 10px 15px;
  /* border: 1px solid red; */
}

.profile-designation__warning {
  color: rgb(163, 163, 163);
}

.user-profile-complete {
  width: 30%;
  /* height: 100%; */
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 18px 5px 63px rgba(230, 240, 255, 0.47);
  border: 1px solid #e9f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
  /* border: 1px solid red; */
}

.progress-circle {
  height: 7vw;
  width: 7vw;
  margin-top: 0;
  margin-bottom: 10px;
  /* border: 1px solid red; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.percentage {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "PoppinsSemiBold";
  font-size: 1.3vw;
}

.percentage span {
  font-family: "PoppinsRegular";
  font-size: 0.7vw;
}

.user-gsat {
  width: 30%;
  /* height: 100%; */
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 18px 5px 63px rgba(230, 240, 255, 0.47);
  border: 1px solid #e9f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
  /* border: 1px solid red; */
}

.user-gsat .percentage {
  color: #0979f9;
  font-family: "PoppinsSemiBold";
  font-size: 1.3vw;
}

.user-gsat .percentage .days {
  color: #0979f9;
  font-family: "PoppinsSemiBold";
  font-size: 0.9vw;
  margin-top: -0.5vw;
}

.user-gsat .percentage .remaining {
  font-family: "PoppinsRegular";
  font-size: 0.7vw;
  color: #000;
}

.history {
  height: auto;
  padding: 2vw 0;
  /* height: calc(60% - 20px); */
  width: 100%;
  margin-top: 1vw;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 18px 38px 63px rgba(230, 240, 255, 0.47);
  border: 1px solid #e9f4ff;
  font-family: "PoppinsMedium";
}

.table-subheader {
  margin-left: 2vw;
  color: #95aed1;
  font-size: 0.8vw;
}

.table-header {
  margin-left: 2vw;
  font-size: 1.2vw;
}

.overview-table {
  margin-top: 1.5vw;
}

.overview-table th {
  font-weight: normal;
  font-family: "PoppinsMedium";
}

.overview-table td {
  font-weight: normal;
  font-family: "PoppinsRegular";
}

.overview-table tr {
  font-size: 0.75vw;
}

.table > :not(caption) > * > * {
  padding-left: 2vw !important;
}

.table-row-header {
  background-color: #edf2f9;
  color: #95bce0;
}

.accepted {
  color: #3dc31c;
}

.rejected {
  color: #f01e1f;
}

.pending {
  color: #ffa200;
}

.draft {
  color: #626262;
}

.table-icon-delete {
  font-size: 1vw;
  color: #f01e1f;
  cursor: pointer;
  opacity: 0.5;
}

.table-icon-delete:hover {
  opacity: 1;
}

.table-icon {
  font-size: 1vw;
  color: #95bce0;
  cursor: pointer;
}

.table-header-icon-container {
  height: 1vw;
  width: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 0.5px solid red; */
}

.table-header-title {
  display: flex;
}

.table-header-icon {
  font-size: 0.4vw;
}

.table-icon:hover {
  color: #0979f9;
}

.table-icon-margin-left {
  margin-left: 1vw;
}
