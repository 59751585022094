.btnContainer {
  border-radius: 10px;
  border: 2px solid #edf2f9;
  width: 24.1rem;
  height: 6.563rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: #fff;
}
.labelArea {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox {
  display: none;
}
.radio {
  width: 1.125rem;
  height: 1.125rem;
  border: 2px solid #95bce0;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 8px;
  padding: 2px;
  background-color: #fff;
  cursor: pointer;
}
.radio::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0979f9;
  display: block;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.1s;
}
.btnAction .checkbox + .radio::after {
  transform: scale(1.7);
  border: 2px solid #ffffff;
}
.textArea {
  display: flex;
  flex-direction: column;
}
.headerText {
  font-size: 1.125rem;
  font-family: "PoppinsMedium";
  cursor: pointer;
}
.subTitleText {
  font-size: 0.875rem;
  font-family: "PoppinsRegular";
  cursor: pointer;
}
/* .btnContainer:hover {
  transform: translate(5px, -5px);
  transition: transform 0.5s;
} */

.imageRed {
  background-image: url(../../../../../../assets/images/card/Group\ 486.svg);
}
.imageBlue {
  background-image: url(../../../../../../assets/images/card/Group\ 487.svg);
}
.imageGray {
  background-image: url(../../../../../../assets/images/card/Group\ 488.svg);
}
.imagePink {
  background-image: url(../../../../../../assets/images/card/Group\ 490.svg);
}
.imageViolet {
  background-image: url(../../../../../../assets/images/card/Group\ 489.svg);
}
.imageGreen {
  background-image: url(../../../../../../assets/images/card/Group\ 494.svg);
}
.imageYellow {
  background-image: url(../../../../../../assets/images/card/Group\ 495.svg);
}

.containerHover {
  background-color: #2c7be5;
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .btnContainer {
    width: 22.1rem;
    height: 6.563rem;
  }
}
@media screen and (max-width: 375px) {
  .btnContainer {
    width: 18.2rem;
    height: 6.463rem;
  }
}
