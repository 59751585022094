.popup-container {
  margin: 0;
  padding: 0;
  /* height: 105vh;
  width: 99vw; */
  height: 100vh;
  width: 100vw;
  background-color: rgba(149, 188, 224, 0.21);
  filter: blur(13px) brightness(110%);
  -webkit-filter: blur(13px) brightness(110%);
  overflow: hidden;
}

.msg-container {
  min-width: 15vw;
  height: auto;
  /* height: 27vh; */
  padding: 4rem 6rem;
  /* padding: 30px 50px; */

  /* min-height: auto;
  min-width: auto; */

  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.16);
  border-radius: 38px;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.msg-container:hover {
  cursor: pointer;
}

.icon-check {
  color: #59df59;
  font-size: 5rem;
}

.icon-check-red {
  color: #f01e1f;
  font-size: 5rem;
}

.font-md {
  font-family: "PoppinsSemiBold";
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1rem;
  /* border: 1px solid red; */
}

.font-reg {
  font-family: "PoppinsRegular";
  font-size: 0.9rem;
  margin-top: 1rem;
  text-align: center;
}

.blue {
  color: #0979f9;
}
