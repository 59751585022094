.label {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}
.imageArea {
  width: 10.688rem;
  height: 10.688rem;
  position: relative;
}
.imageWrapper {
  width: 10.688rem;
  height: 10.688rem;
  border-radius: 50%;
  border: 5px solid #2c7be5;
  position: absolute;
  overflow: hidden;
}
.image {
  /* margin-top: 15px; */
  /* padding: 10px; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  /* width: 80%; */
  height: auto;
  /* border-radius: 50%; */
  position: absolute;
  z-index: 0;
}

.image2 {
  width: 100%;
  object-fit: contain;
}

/* Lower part */
.iconText {
  width: 100%;
  height: 50%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  background-color: rgba(10, 10, 10, 0.68);
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.iconText:hover {
  opacity: 1;
  transition: ease 1s;
}
.input {
  visibility: hidden;
}
/* Cross */
.iconOuter {
  background-color: #fff;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid #deeeff;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 12%;
  box-shadow: 0 3px 5px rgba(239, 246, 254, 0.75);
}
.iconInner {
  cursor: pointer;
  font-size: 23px;
  color: #ff0000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Right side */
.text {
  display: flex;
  justify-content: flex-start;
  margin-top: 4rem;
}
.textBold {
  font-family: "PoppinsSemiBold";
}
