.navbar-bg {
  background-color: #fff;
  box-shadow: 6px 6px 10px #eff6fe;
  padding: 0 4vw;
  height: 8vh;
  /* height: 60px; */
  min-height: 60px;
  font-family: "PoppinsSemiBold";
}

.logo {
  width: 6vh;
  min-width: 50px;
  height: auto;
  margin-right: 15px;
  /* margin-top: 15px; */
}

.navbar-light .navbar-brand {
  color: #010247 !important;
  font-size: 1.1vw;
  /* font-size: 1.2em; */
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #000 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #000 !important;
}

.notification {
  margin-top: 10px;
  height: 60px;
  /* height: 8vh; */
  width: 60px;
  /* min-height: 60px;
  min-width: 60px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* border: 1px solid red; */
}

.notification:hover {
  cursor: pointer;
}

.notification-container {
  /* max-height: 450px; */
  /* height: 23vw; */
  /* width: 22vw; */
  /* max-width: 380px; */
  /* min-width: 250px; */
  /* border: 1px solid red; */
  z-index: 10;
  position: absolute;
  top: 60px;
  right: -10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.16);
  /* overflow: hidden; */
  /* overflow-y: scroll; */
}

.notification-count {
  position: absolute;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f01e1f;
  top: 30px;
  left: 35px;
}

.profile-pic-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-height: 60px;
  font-family: "PoppinsMedium";
  font-size: 1vw;
  /* border: 1px solid red; */
  padding-left: 20px;
  border-left: 1px solid #e9e9e9;
}

.profile-pic-container .profile-pic {
  width: 5vh;
  height: 5vh;
  min-width: 50px;
  min-height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  /* display: flex;
  align-items: center;
  justify-content: center;
  position: relative; */
  /* padding-top: 1vh; */
  overflow: hidden;
  border: 3px solid #f9fbfd;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11);
}

.profile-pic-container .profile-pic img {
  width: 100%;
  object-fit: contain;
  /* height: auto;
  position: absolute; */
}

.dropdown-toggle::after {
  content: none !important;
}

.dropdown-menu {
  box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.16);
  border: none !important;
  border-radius: 10px !important;
  min-width: 11rem !important;
  /* top: 7.3vh !important; */
  top: 65px !important;
  left: 30px !important;
}

.drop-down-menu {
  font-family: "PoppinsMedium";
  font-size: 1vw;
  height: 4vh;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.dropdown-divider {
  border: 1px solid #e9e9e9 !important;
}

/* .dropdown-item:nth-child(1) {
  border-bottom: 1px solid #e9e9e9;
} */

.dropdown-item:hover {
  color: #000 !important;
  background-color: #fff !important;
}

.icon-chevron-down {
  color: "#000";
  margin-left: 5px;
}

.icon-dropdown {
  color: #95bce0;
  margin-right: 10px;
  font-size: 1.6rem;
}

.icon-bell {
  color: #0979f9;
  font-size: 1.8rem;
}

.dropdown-item:hover .icon-dropdown {
  color: #0979f9;
}

.toggle-nav {
  margin-top: 21px;
  position: relative;
  display: inline-block;
  width: 67px;
  height: 34.5px;
  background-color: #f5f5f5;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
  cursor: pointer;
}

/* After slide changes */
.toggle-nav:after {
  content: "";
  position: absolute;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: #e2e2e2;
  background-image: url(../../../assets/images/gb.svg);
  background-position: center;
  background-size: 34px 34px;
  top: 1.5px;
  left: 3px;
  transition: all 0.2s;
}

/* Toggle text */
.textLanguage-nav {
  font-size: 16px;
  font-family: "PoppinsSemiBold";
  color: #010247;
  padding-top: 5px;
  padding-left: 10px;
  /* text-align: center; */
  margin: 0;
}

/* Checkbox checked effect */
.checkbox-nav:checked + .toggle-nav::after {
  left: 34px;
}

/* Checkbox checked toggle label bg color */
.checkbox-nav:checked + .toggle-nav:after {
  background-color: #e2e2e2;
  background-image: url(../../../assets/images/bd.png);
  /* background-position: 34px 32px; */
  background-size: 30px 30px;
}

/* Checkbox vanished */
.checkbox-nav {
  display: none;
}
