.authorizationArea {
  width: 100%;
}
.authorizationAreaLabel {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
  margin-bottom: 0.2rem;
}
/* .checkbox-container {
    border-bottom: 1px solid #e3ebf6;
} */
.checkboxArea {
  margin: 0.5rem 0;
}
.checkboxAreaRow {
  display: flex;
  width: 100%;
}
/* .checkbox-area_col1 {
    width: 40%;
}
.checkbox-area_col2 {
    display: flex;
    width: 60%;
    align-items: flex-start;
} */
