.content-adjuster {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 10px;
  /* border: 1px solid red; */
}
.inner-content {
  margin-top: 3.125rem;
  width: 50.172rem;
  height: auto;
  /* border: 1px solid green; */
}
@media screen and (max-width: 768px) {
  .inner-content {
    width: 46.172rem;
  }
}
@media screen and (max-width: 425px) {
  .inner-content {
    width: 23.172rem;
  }
}
@media screen and (max-width: 375px) {
  .inner-content {
    width: 22.172rem;
  }
}
@media screen and (max-width: 375px) {
  .inner-content {
    width: 18.172rem;
  }
}
