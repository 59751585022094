.item{
    text-decoration: none;
    list-style: none;
    width: 100%;
    color: #95BCE0;
    z-index: 2;
    position: relative;
}
.activeItem{
    color: #2C7BE5;
}
.dot{
    height: 15px;
    width: 15px;
    background-color: #95BCE0;
    border-radius: 50%;
    display: inline-block;
}
.activeDot{
    background-color: #2C7BE5;
}
.item:not(:last-child) .circleArea::after{
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    margin-top: 11px;
    background-color: #e4e7eb;
    z-index: -1;
}
.text{
    font-size: 14px;
    font-family: "PoppinsMedium";
}
