.area {
  width: 100%;
}
.label {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}
.input {
  font-size: 1rem;
  font-family: "PoppinsRegular";
}
/* .input::-webkit-calendar-picker-indicator {
    background-image: url(../../../../../../assets/images/utility_icons/calander-icon.svg);
}
.input::-moz-calendar-picker-indicator {
    background-image: url(../../../../../../assets/images/utility_icons/calander-icon.svg);
} */
