.notification-content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
}
.notification-inner-content {
  margin-top: 3.125rem;
  width: 50.172rem;
  height: auto;
}
.mark-all-read {
  color: #0979f9;
  font-size: 0.75rem;
  font-family: "PoppinsMedium";
  cursor: pointer;
}

.mark-all-read:hover {
  opacity: 0.65;
}
