.createAdminFlag {
  margin-top: 5px;
  width: 3rem;
  height: auto;
  margin-right: 10px;
}

.countryCode {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  max-height: 40px;
  background-color: #fff;
  /* max-width: 40px; */
}

/* input:disabled {
  background-color: #fff;
  max-width: 40px;
} */
