.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.9rem;
}

.headerImage {
  margin-bottom: 1.5rem;
  width: 10vw;
  max-width: 250px;
}

.headerTitle {
  font-size: 1.625rem;
  font-family: "PoppinsMedium";
}

.headerSubtitle {
  font-size: 0.875rem;
  font-family: "PoppinsRegular";
  margin: 0;
}

.inputArea {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: center;
  padding: 1rem 16rem;
  border-bottom: 1px solid #e3ebf6;
  padding-bottom: 3rem;
  /* border: 1px solid red; */
}

.inputField {
  width: 4.688rem;
  height: 5.438rem;
  border-radius: 10px;
  background-color: #dfecff;
  color: #9b9b9b;
  text-align: center;
  font-size: 3.125rem;
  font-family: "PoppinsSemiBold";
  /* cursor: pointer; */
  transform: scale(1);
  transition: transform 0.1s;
  /* caret-color: #469CFF underscore; */
  /* caret: #ff7a18 underscore; */
}

.inputField:focus {
  background-color: #fff;
  border-color: #edf2f9;
  outline: 0;
  border: 1px solid #edf2f9;
  color: #000;
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
  transform: scale(1.1);
}

.btnArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blueBtn {
  margin: 2rem 0rem;
  width: 7.563rem !important;
  height: 2.563rem !important;
  background-color: #0979f9;
  color: #fff;
  border-radius: 5px;
  font-size: 1.125rem;
  font-family: "PoppinsMedium";
  text-align: center;
}
.blueBtn:disabled,
.blueBtn[disabled] {
  pointer-events: none;
  opacity: 0.65;
}
.icon {
  margin-left: 0.5rem;
}
