.marginHeader {
  margin-top: 1.8rem !important;
}

.headerAttachment {
  font-size: 1.125rem;
  color: #2c7be5;
  font-family: "PoppinsSemiBold";
}

.headerText {
  font-size: 1rem;
  font-family: "PoppinsMedium";
}

.text {
  font-size: 0.75rem;
  font-family: "PoppinsRegular";
}

.signArea {
  width: 100%;
  margin-bottom: 50px;
}

.validity {
  margin-top: -30px;
  margin-bottom: 50px;
}

.label {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
  margin-bottom: 20px !important;
}

.uploadPictureFieldLabel {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}

.fileUploadAsset {
  min-height: 3.75rem;
  /* width: 48%; */
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  font-family: "PoppinsRegular";
  position: relative;
  cursor: pointer;
  font-size: 0.75rem;
  /* border: 1px solid red; */
}

.assetFileName {
  /* border: 1px solid red; */
  max-width: 70%;
}

.iconArchive {
  font-size: 1.875rem;
  color: #95bce0;
}

.iconDownload {
  font-size: 1.875rem;
  color: #2c7be5;
  position: absolute;
  right: 0;
}

.assetContainer {
  display: flex;
  flex-direction: row;
}

.leftPortion {
  /* border: 1px solid red; */
  padding-right: 1rem;
}

.rightPortion {
  /* border: 1px solid red; */
  padding-left: 1rem;
}

.pictureField {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.imageStyling {
  width: 12.125rem;
  height: auto;
  box-shadow: 4px 4px 10px rgba(199, 226, 255, 0.51);
}
