.global {
  width: 100vw;
  height: 100vh;
  background-color: #f9fbfd;
}

.header-bar {
  position: sticky;
  width: 100vw;
  top: 0;
  z-index: 3;
}

.side-bar {
  position: fixed;
  z-index: 2;
  height: 92vh;
  width: 16vw;
  /* max-width: 267px; */
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.body-content {
  height: calc(92vh - 40px);
  /* height: calc(92vh - 2.4rem - 40px); */
  width: calc(84vw - 1.5rem);
  /* margin-top: 1.2rem; */
  /* margin-left: calc(16vw + 1vh); */
  margin-left: 17vw;
  overflow: auto;
  /* padding-bottom: 1vw; */
  scrollbar-width: thin;
  scrollbar-color: rgb(11, 2, 51) #f9fbfd;

  /* border: 1px solid red; */
}

.dynamic-content {
  min-height: calc(92vh - 3rem - 40px);
  /* height: 83vh; */
  width: calc(84vw - 2.5rem);
  background-color: #fff;
  border-radius: 10px;
  margin: 1vw 0;
  border: 1px solid #edf2f9;
  box-shadow: 18px 38px 63px rgba(230, 242, 255, 0.47);
  /* background-color: transparent; */
}

.footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  height: 40px;
  width: 100%;
  background-color: #fff;
  /* text-align: right; */
  padding: 12px 4vw;
  /* border: 1px solid red; */
  font-family: "PoppinsRegular";
  /* font-size: 0.55vw; */
  font-size: clamp(0.5rem, 0.5rem + 0.55vw, 0.7rem);
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.footer span {
  color: #1592e6;
  text-decoration: underline;
}

.footer p:nth-child(1) {
  margin-left: calc((100% - 10vw) / 2);
  /* transform: translateX(50%); */
}

.footer p:nth-child(2) {
  margin-left: auto;
}

/* ============ model blur background css ============ */
.modal-backdrop {
  background-color: rgba(149, 188, 224, 0.31) !important;
}
body.modal-open .global {
  -webkit-filter: blur(12px) brightness(100%);
  -moz-filter: blur(12px) brightness(100%);
  -o-filter: blur(12px) brightness(100%);
  -ms-filter: blur(12px) brightness(100%);
  filter: blur(12px) brightness(100%);
}
