.area{
    width: 100%;
}
.label{
    font-size: 1.125rem;
    font-family: "PoppinsMedium";
}
.button{
    color: #fff !important;
    background-color: #0979F9 !important;
}