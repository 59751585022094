.titleArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.starterText {
  font-size: 0.875rem; /* 14px */
  font-family: "PoppinsMedium";
  color: #95aed1;
  margin-bottom: 0.625rem;
}
.headerText {
  font-size: 1.625rem; /* 26px */
  font-family: "PoppinsMedium";
  margin-bottom: 0.625rem;
}
.subText {
  font-size: 0.875rem; /* 14px */
  font-family: "PoppinsRegular";
}

.contentAdjuster {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: center; */
  align-items: center;
  /* border: 1px solid red; */
  border: 1px solid #e9f4ff;
  min-height: 82vh;
  background-color: #fff;
  border-radius: 10px;
}

.innerContent {
  margin-top: 3.125rem;
  width: 50.172rem;
  height: auto;
  /* border: 1px solid red; */
}

.headerContent {
  border-bottom: 1px solid #e3ebf6;
  padding-bottom: 20px;
}

.tableNameContain {
  display: flex;
  align-items: center;
}

.areaImage {
  width: 2rem;
  height: 2rem;
  border: 1px solid #95bce0;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.nameImage {
  width: 100%;
  height: auto;
  position: absolute;
}

.nameText {
  padding-left: 0.9rem;
  white-space: nowrap;
}

.tableContainer {
  width: 100%;
}

.tableHeader {
  background-color: #f8fbfd;
}

.tableHeader tr {
  border-top: 1.5px solid #edf2f9;
  color: #95bce0;
  font-size: 1rem;
  font-family: "PoppinsMedium";
}

.tableHeaderText {
  display: flex;
  align-items: center;
}

.tableHeaderTitle {
  display: flex;
  align-items: center;
}

.tableHeaderIconContainer {
  padding: 0 0.5rem;
  margin: 0 0.3rem;
  height: 0.5rem;
  width: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 0.5px solid red; */
}

.tableHeaderIcon {
  font-size: 0.5rem;
}

/* .overviewTable {
  margin-top: 1.5vw;
} */

.overviewTable tr {
  font-size: 0.75vw;
}

.overviewTable > :not(caption) > * > * {
  padding-left: 2vw !important;
}

.tableBody {
  background-color: #fff;
  border: 1px solid #edf2f9 !important;
  border-left: none !important;
  border-right: none !important;
}
.tableBody tr {
  font-size: 1rem;
  font-family: "PoppinsRegular";
}
.tableBody tr td {
  padding: 1rem 0.5rem;
}
.tableBody tr th {
  padding: 1rem 0.5rem;
}
.tableActionIcon {
  font-size: 1.25rem;
  color: #95bce0;
  cursor: pointer;
}
.tableActionIcon:hover {
  color: #0979f9;
}
.textColorGreenCol {
  color: #3dc31c !important;
}
.textColorRedCol {
  color: #ff4849 !important;
}

/* tooltips */
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltipText {
  visibility: hidden;
  width: 6.25em;
  background-color: #010247;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0;
  font-size: 0.625rem;
  font-family: "PoppinsRegular";
  /* Position the custom-tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 100%;
  margin-left: -28px;
}
.tooltip .tooltipText::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 23%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #010247 transparent;
}
.tooltip:hover .tooltipText {
  visibility: visible;
}

.searchContainer {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border: 1px solid red; */
}

.selectField {
  width: 49%;
  /* border: 1px solid yellow; */
}

.inputArea {
  margin-left: 10px;
  width: 49%;
  /* border: 1px solid green; */
}

.searchArea {
  background-color: #fff !important;
  border-right: 0;
}

.searchArea svg {
  color: #0979f9;
}
.selectOption {
  font-family: "PoppinsRegular";
  font-size: 0.8vw;
}
.searchInput {
  font-family: "PoppinsRegular";
  border-left: 0;
  font-size: 0.8vw !important;
}
.searchInputField {
  font-family: "PoppinsRegular";
  font-size: 0.8vw !important;
}
.searchDateInput {
  font-family: "PoppinsRegular";
  font-size: 0.8vw !important;
}
.btnSearchSubmit {
  background-color: #0979f9 !important;
  color: #fff !important;
  font-size: 0.8vw !important;
}

/* ================= modal ================ */
/* the background blur porperties are applied in the layout css */
.applicantDetailModel {
  max-width: 54rem;
  box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.16) !important;
  border-radius: 1em !important;
  border: none;
}
/* .applicantDetailModel .modalDialog .modalContent {
  box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.16) !important;
  border-radius: 38px;
} */
.applicantDetailModelBody .areaModelContent {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0rem;
}
.applicantDetailModelBody .areaModelContent .modelTopContent {
  padding: 0rem 2.9rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-self: start;
  width: 100%;
  /* border: 1px solid red; */
}
.applicantDetailModelBody .areaModelContent .areaModelHeader {
  font-size: 1.625rem;
  font-family: "PoppinsSemiBold";
  margin-bottom: 0.875rem;
  flex-wrap: wrap;
  display: flex;
}
.applicantDetailModelBody .areaModelContent .areaModelText {
  font-size: 1rem;
  font-family: "PoppinsRegular";
  margin-bottom: 0.5rem;
}
.applicantDetailModelBody
  .areaModelContent
  .areaModelText
  .areaModelListHeader {
  font-size: 1rem;
  font-family: "PoppinsMedium";
}
.applicantDetailModelBody .areaModelContent .areaModelText .areaModelListText {
  font-size: 0.875rem;
  font-family: "PoppinsRegular";
}
/* .model-btn-area{} */
.applicantDetailModelBody .areaModelContent .modelBtnArea .btnModel {
  width: 6rem;
  margin: 0rem 0.8rem;
  font-family: "PoppinsMedium";
  font-size: 1rem;
}
.applicantDetailModelBody .areaModelContent .modelBtnArea .btnModelRed {
  background-color: #ff3a41 !important;
  color: #fff !important;
}
.applicantDetailModelBody .areaModelContent .modelBtnArea .btnModelPrimary {
  background-color: #0979f9 !important;
  color: #fff !important;
}
