.card-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100px;
  font-family: "PoppinsMedium";
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  position: relative;
  cursor: pointer;
  /* border: 1px solid red; */
}

.left-content {
  /* padding-left: 1rem; */
  direction: row;
  display: flex;
  justify-content: flex-start;
  width: 80%;
  /* flex-wrap: nowrap; */
  align-items: center;
  /* border: 1px solid red; */
}

.custom-body-text {
  margin-top: 2px;
  font-family: "PoppinsRegular";
  color: #b8b8b8;
  font-size: 0.725rem;
  /* border: 1px solid red; */
}

.body-container {
  padding: 0 2rem;
  width: 80%;
  /* border: 1px solid red; */
}

.time-container {
  padding-right: 2rem;
  font-family: "PoppinsMedium";
  color: #b8b8b8;
  font-size: 0.6rem;
  /* border: 1px solid red; */
}

/* .card-container:hover {
  background-color: #f6f9fd;
} */

.icon-bg-green,
.icon-bg-red,
.icon-bg-yellow,
.icon-bg-gray {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.icon-bg-green {
  background-color: #dbffd2;
}
.icon-bg-red {
  background-color: #ffd4d4;
}
.icon-bg-yellow {
  background-color: #ffeed1;
}
.icon-bg-gray {
  background-color: #e2e2e2;
}

.icon-file {
  font-size: 1.15rem;
}

.icon-bg-green .icon-file {
  color: #44c524;
}
.icon-bg-red .icon-file {
  color: #f01e1f;
}
.icon-bg-yellow .icon-file {
  color: #ffa200;
}
.icon-bg-gray .icon-file {
  color: #626262;
}

.custom-header-text {
  font-size: 0.875rem;
}

.all-notifications {
  height: 50px;
  color: #0979f9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f9fd;
  font-size: 0.8rem;
}
