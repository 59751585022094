.card-layout-current {
  padding: 2rem 10vw;
}

.logo-avsecID {
  width: auto;
  /* max-width: 150px; */
  height: 5rem;
  /* margin-bottom: 20px; */
  margin-left: -10px;
  /* border: 1px solid red; */
}

.card-layout {
  width: 30vmax;
  min-width: 350px;
  /* margin-left: auto;
    margin-right: 10vmax;
    margin-top: 5vh; */

  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  /* margin-left: 40vmin;
    margin-top: 10vmin; */
}

@media screen and (max-width: 767px) {
  .card-layout {
    /* width: 50vmax; */
    min-width: 300px;
    margin: 5vh auto;

    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

.page-title {
  font-family: "PoppinsSemiBold";
  color: #0979f9;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.page-subtitle {
  font-family: "PoppinsSemiBold";
  font-size: 1rem;
}

.form-text {
  font-family: "PoppinsSemiBold";
}

.text-md {
  font-size: 0.8rem;
  font-family: "PoppinsSemiBold";
}

.step-container {
  width: 100%;
  height: 10vh;
  position: relative;
  display: flex;
  align-items: center;
}

.forget-toggle {
  margin-top: 10px;
  position: relative;
  display: inline-block;
  width: 67px;
  height: 34.5px;
  background-color: #f5f5f5;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
}

/* After slide changes */
.forget-toggle:after {
  content: "";
  position: absolute;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: #e2e2e2;
  background-image: url(../assets/images/gb.svg);
  background-position: center;
  background-size: 34px 34px;
  top: 2px;
  left: 3px;
  transition: all 0.2s;
}

/* Toggle text */
.forget-textLanguage {
  font-size: 16px;
  font-family: "PoppinsSemiBold";
  color: #010247;
  padding-top: 5px;
  padding-left: 10px;
  /* text-align: center; */
  margin: 0;
}

/* Checkbox checked effect */
.forget-checkbox:checked + .forget-toggle::after {
  left: 34px;
}

/* Checkbox checked toggle label bg color */
.forget-checkbox:checked + .forget-toggle:after {
  background-color: #e2e2e2;
  background-image: url(../assets/images/bd.png);
  /* background-position: 34px 32px; */
  background-size: 30px 30px;
}

/* Checkbox vanished */
.forget-checkbox {
  display: none;
}
