/* .leftDiv {
  border: 1px solid red;
  display: flex;
  align-items: center; 
} */

.uploadRight {
  /* margin-left: 1rem; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  margin-top: 6.219rem;
  transform: translateY(-50%);
}

.content {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* border: 1px solid red; */
}

.iconSize {
  color: #2c7be5;
  font-size: 1.2rem;
}

.text {
  /* width: 21.5rem;
  height: 4.313rem; */
  font-size: 0.875rem;
  /* padding-left: 0.1rem; */
  margin-left: 1rem;
  font-family: "PoppinsRegular";
}

/* area */
.btnArea {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-left: 2.2rem;
  /* border: 1px solid red; */
}

/* button */
.button {
  font-family: "PoppinsMedium";
  width: 7.563rem;
  height: 2.563rem;
  border-radius: 0.4rem;
}

.leftSpace {
  margin-left: 1.5rem;
}

.checkboxArea {
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
  align-items: center;
}

.checkboxText {
  margin-left: 0.1rem;
}
