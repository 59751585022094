.checkField {
  width: 100%;
}
.checkFieldInput {
  margin-top: 0.37rem !important;
}
.checkFieldLabel {
  font-size: 1.125rem;
  font-family: "PoppinsRegular";
  opacity: 1 !important;
}
