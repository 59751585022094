.genderArea{
    width: 100%;
}
.genderLabel{
    font-size: 1.125rem;
    font-family: "PoppinsSemiBold";
}
.button{
    color: #fff !important;
    background-color: #0979F9 !important;
}
.pictureArea{
    width: 100%;
}
.pictureLabel{
    font-size: 1.125rem;
    font-family: "PoppinsMedium";
}
.boldText{
    font-family: "PoppinsSemiBold";
}