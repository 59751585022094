.toggleContainer {
  /* border: 1px solid red; */
  position: absolute;
  top: 15px;
  left: 15px;
}

.toggle {
  margin-top: 10px;
  position: relative;
  display: inline-block;
  width: 67px;
  height: 34.5px;
  background-color: #f5f5f5;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
}

/* After slide changes */
.toggle:after {
  content: "";
  position: absolute;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: #e2e2e2;
  background-image: url(../../assets/images/gb.svg);
  background-position: center;
  background-size: 34px 34px;
  top: 2px;
  left: 3px;
  transition: all 0.2s;
}

/* Toggle text */
.textLanguage {
  font-size: 16px;
  font-family: "PoppinsSemiBold";
  color: #010247;
  padding-top: 5px;
  padding-left: 10px;
  /* text-align: center; */
  margin: 0;
}

/* Checkbox checked effect */
.checkbox:checked + .toggle::after {
  left: 34px;
}

/* Checkbox checked toggle label bg color */
.checkbox:checked + .toggle:after {
  background-color: #e2e2e2;
  background-image: url(../../assets/images/bd.png);
  /* background-position: 34px 32px; */
  background-size: 30px 30px;
}

/* Checkbox vanished */
.checkbox {
  display: none;
}

/* .columnBackground {
  border: 1px solid red;
  position: relative;
} */

.containerBackground {
  background-image: url("../../assets/images/bg2.png");
  /* background-position: right; */
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 98vw;
  min-height: 98vh;
}

.bannerImage {
  /* border: 1px solid red; */
  position: absolute;
  height: 10rem;
  width: auto;
  top: 15px;
  right: 15px;
}

.caabImage {
  /* border: 1px solid red; */
  /* position: absolute; */
  position: fixed;
  height: 5rem;
  width: auto;
  bottom: 15px;
  right: 15px;
}

.containerRow {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 100%; */
  min-height: 100vh;
}

.imageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.carouselImage {
  max-height: 100vh;
  width: auto;
  /* border: 1px solid red; */
}

/* @media screen and (max-width: 767px) {
  .columnBackground {
    background-image: none;
  }
} */
