.textInputArea {
  width: 100%;
}
.textInputAreaLabel {
  font-size: 1.125rem;
  font-family: "PoppinsSemiBold";
}
.textInputAreaInput {
  font-size: 1rem;
  font-family: "PoppinsRegular";
}
