.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 35px;
  font-family: "PoppinsMedium";
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(218, 216, 216);
  background-color: #fff;
}

.active-step {
  color: #fff;
  background-color: #0979f9;
}

.step-block {
  width: 100%;
}

.step-block .circle-wrapper {
  position: relative;
}

.step-block:not(:last-child) .circle-wrapper::after {
  content: "";
  width: 100%;
  height: 4px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  position: absolute;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.success-step-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 4px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0979f9;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.success-step {
  height: 8px;
  width: 15px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  margin-bottom: 4px;
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
  transform: rotate(-45deg);
}

/* .checkmark {
  display: inline-block;
  width: 40px;
  height: 40px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg); 
  transform: rotate(45deg);
}

.checkmark-circle {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #0979f9;
  border-radius: 50%;
  left: 0;
  top: 0;
}

.checkmark-stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 11px;
  top: 6px;
}

.checkmark-kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fff;
  left: 8px;
  top: 12px;
} */
